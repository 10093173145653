import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/myHome.vue')
  },
  {
    path: '/productsServices',
    name: 'productsServices',
    component: () => import('../views/productsServices.vue')
  },
  {
    path: '/mediaCenter',
    name: 'mediaCenter',
    component: () => import('../views/mediaCenter.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/usAbout.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
