import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import  './utils/rem'
import 'element-ui/lib/theme-chalk/index.css';
import { Button, Select, Container, Header, Main, Footer, Menu, MenuItem, Submenu, Row, Col } from 'element-ui'

Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Container.name, Container);
Vue.component(Header.name, Header);
Vue.component(Main.name, Main);
Vue.component(Footer.name, Footer);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Submenu.name, Submenu);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
