<template>
  <div id="app">
    <el-row>
      <el-col :span="12" class="logo">
        <img src="./assets/logo.png" alt />
      </el-col>
      <el-col :span="12" class="nav">
        <!-- <input type="text" placeholder="搜索产品"> -->
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1" @click="toMyHome">首页</el-menu-item>
          <el-menu-item index="2" @click="toProductsServices">产品与服务</el-menu-item>
          <el-menu-item index="3" @click="toMediaCenter">媒体中心</el-menu-item>
          <el-menu-item index="4" @click="toUsAbout">关于我们</el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
    <router-view />
    <el-footer>
      <p>
        Copyright ©2022 All Rights Reserved
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >苏ICP备2022040392号</a>
      </p>
      <p>地址：昆山市花桥镇金洋路15号国际创新港B6栋5楼 电话：0512-55133592 凯杰智享（苏州）信息科技有限公司</p>
    </el-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: localStorage.getItem('activeIndex') || '1',
      loadFlag: true,
    }
  },
  mounted() {
    window.onbeforeunload = () => {
      let storage = window.localStorage
      storage.clear()
    }
    window.onload = () => {
      this.toMyHome()
    }
  },
  methods: {
    handleSelect(key) {
      localStorage.setItem('activeIndex', key)
      this.activeIndex = key
    },
    toProductsServices() {
      this.$router.push('/productsServices')
    },
    toMyHome() {
      this.$router.push('/')
    },
    toMediaCenter() {
      this.$router.push('/mediaCenter')
    },
    toUsAbout() {
      this.$router.push('/about')
    },
  },
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.el-row {
  display: flex;
  align-items: center;
}
.logo,
.nav {
  overflow: hidden;
}
.nav {
  position: relative;
}
.logo img {
  float: left;
  margin-left: 300px;
}
.nav input {
  border: 0px;
  background-color: #f1f1f1;
  outline: none;
  border-radius: 0.9375rem;
  text-indent: 0.625rem;
  padding: 0.3125rem 0;
  position: absolute;
  top: 50%;
  margin-top: -0.78125rem;
  left: 3.125rem;
}
.nav ul {
  float: right;
  margin-right: 240px;
}
.el-header,
.el-footer {
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-footer {
  background-color: #4c4c4c;
  color: #d4d4d4;
  height: auto !important;
  font-size: 14px;
  padding: 40px 0 !important;
}

.el-footer p {
  height: 30px;
  line-height: 30px;
}
.el-footer a {
  color: #d4d4d4;
  text-decoration: none;
}
</style>
